.transport-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 13;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.5s ease;

  &.hidden {
    transform: scale(0%);
    pointer-events: none;
  }
}
