.debug {
  position: fixed;
  top: 4rem;
  left: 1rem;
  width: calc(100vw - 2rem);
  height: calc(100% - (4rem * 2));
  text-shadow: var(--main-background) 0 0 8px;
  text-align: left;
  padding: 1em;
  font-size: 66%;
  overflow-y: auto;
  color: var(--main-typography);
  pointer-events: none;
  line-height: 1rem;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .item-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  @include screenSize(mdMobile) {
    width: 75vw;
    left: calc(50% - 37.5vw);
  }

  .debug-item {
    width: 25vw;
    padding: 0.5rem;

    @include screenSize(mdMobile) {
      width: calc(100vw - 2rem);
    }
  }

  .debug-table {
    width: 100%;
    border: 1px solid var(--main-typography);

    tr {
      border-bottom: 1px solid var(--main-typography);

      td {
        border-right: 1px solid var(--main-typography);
        position: relative;

        .play-indicator {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          max-width: 0;
          background-color: transparent;

          &.active {
            max-width: 100%;
            background-color: #88888844;
          }
        }
      }
    }
  }
}
