@mixin fastTransition() {
  transition: all 0.5s ease;
}

.mixer-button {
  i {
    @include fastTransition();
  }
  &:hover {
    i {
      transform: scale(120%);
    }
  }
  &:active {
    i {
      transition: none;
      transform: scale(90%);
    }
  }
}

.mixer-container {
  position: absolute;
  z-index: 11;
  top: 4rem;
  bottom: 4rem;
  left: 0;
  overflow: hidden;
  width: 0;
  max-width: 0;
  @include fastTransition();
  transform: translateX(-2px);

  &.expanded {
    width: 30rem;
    max-width: 100vw;
    transform: translateX(0);

    .mixer {
      right: 0;
      transform: translateX(0);
    }
  }

  .mixer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 30rem;
    max-width: 100vw;
    overflow-y: auto;
    padding: 1rem;
    border-right-width: 0;

    h2 {
      padding-bottom: 1rem;
    }

    .mixer-channel {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0.5rem 0;

      .channel-label {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        min-width: 25%;
        max-width: 100%;
        white-space: no-wrap;
        text-overflow: ellipsis;

        @include screenSize(mdMobile) {
          min-width: 100%;
        }

        .play-indicator {
          color: var(--main-border);
          margin-right: 0.3rem;
          margin-top: 0.1rem;
          font-size: 0.8rem;

          &.fa-play {
            color: var(--main-typography);
          }
        }
      }
      .channel-control {
        display: flex;
        align-items: center;
        // margin-right: 0.5rem;
        min-width: 20%;
        max-width: 100%;

        i {
          position: relative;
          top: 0.2rem;

          &.mute-button {
            min-width: 1.1rem;
            cursor: pointer;
            transition: transform 0.2s ease;

            &:hover {
              transform: scale(120%);
            }
            &:active {
              transform: scale(90%);
              transition: none;
            }
          }
        }

        @include screenSize(mdMobile) {
          min-width: 100%;
          padding: 0.5rem 0;

          .range-container {
            flex: 1;
          }
        }
      }
    }
  }
}

.mixer-button {
  i {
    @include fastTransition();
    font-size: 1.5rem;
  }
  &:hover {
    i {
      transform: scale(120%);
    }
  }
  &:active {
    i {
      transition: none;
      transform: scale(90%);
    }
  }

  @include screenSize(lgMobile) {
    top: 3.5rem;
  }
}
