.divider {
  display: block;
  border-bottom: 1px solid var(--main-typography);
  margin: 1rem 0;

  &.no-margin-top {
    margin-top: 0;
  }
  &.no-margin-bottom {
    margin-bottom: 0;
  }
}
