@mixin fastTransition() {
  transition: all 0.5s ease;
}

.settings-button {
  i {
    @include fastTransition();
  }
  &:hover {
    i {
      transform: scale(120%);
    }
  }
  &:active {
    i {
      transition: none;
      transform: scale(90%);
    }
  }
}

.settings-container {
  position: absolute;
  z-index: 11;
  top: 4rem;
  bottom: 4rem;
  right: 0;
  overflow: hidden;
  width: 0;
  max-width: 0;
  @include fastTransition();
  //   border: 1px solid;
  transform: translateX(2px);

  &.expanded {
    width: 30rem;
    max-width: 100vw;
    transform: translateX(0);

    .settings {
      left: 0;
      transform: translateX(0);
    }
  }

  .settings {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 30rem;
    max-width: 100vw;
    overflow-y: auto;
    padding: 1rem;
    // border: 1px solid var(--main-border);
    border-right-width: 0;

    h2 {
      padding-bottom: 1rem;
    }
  }

  @include screenSize(lgMobile) {
    top: 3.5rem;
  }
}
