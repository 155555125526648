@import './variables.scss';
@import './reset.scss';
@import './mixins.scss';
@import './components/index.scss';

html,
body,
#root,
canvas {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272730; // Dark mode standard
  color: #f7f7f7; // Dark mode standard
}

@include themeColors(); // Load CSS color variables

.App {
  @include bodyText();
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh) * 100); // Dynamic vh unit
  position: fixed;
  background-color: var(--main-background);
  color: var(--main-typography);

  @include transitionColors();
}

h1 {
  // color: f7f7f7;
  @include titleText();
  @include h1Title();
  @include transitionColors();
}
h2 {
  // color: f7f7f7;
  @include titleText();
  @include h2Title();
  @include transitionColors();
}
h3 {
  // color: f7f7f7;
  @include titleText();
  @include h3Title();
  @include transitionColors();
}
h4 {
  // color: f7f7f7;
  @include titleText();
  @include h4Title();
  @include transitionColors();
}

p,
i,
strong,
a,
em,
.fa,
.fal {
  color: var(--main-typography);
  @include transitionColors();
}

.background-blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: opacity 0.5s ease, background-color 0.5s ease;
  background-color: var(--main-background);

  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease, background-color 0.5s ease;
    pointer-events: none;
  }

  &.semi-hidden {
    opacity: 0.75;
  }
}

.graphics-blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-background);
  pointer-events: none;

  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease, background-color 0.5s ease;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--main-typography) transparent;
  overscroll-behavior: none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-typography);
  border-radius: 0;
}
