@mixin headerTransition() {
  transition: all 1s ease;
}

@mixin headerItemTransition() {
  transition: all 0.5s ease;
}

@keyframes pulsate {
  0% {
    transform: scale(80%);
  }
  11% {
    transform: scale(85%);
  }
  15% {
    transform: scale(90%);
  }
  19% {
    transform: scale(85%);
  }
  30% {
    transform: scale(80%);
  }
  100% {
    transform: scale(80%);
  }
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include headerTransition();
  position: relative;

  height: 100vh;
  height: calc(100 * var(--vh));

  &.loading {
    background-color: var(--main-background);
    color: var(--main-typography);

    .header-item {
      &.logo {
        top: calc(50% - 4rem);
        transform: scale(66%);
        animation: pulsate 1s ease infinite;
      }
      &.title {
        top: calc(50% + 2rem);
        transform: scale(0%);
      }
      &.mixer {
        transform: scale(0%);
      }
      &.picker {
        top: calc(50% + 7rem);
        transform: scale(0%);
      }

      &.settings {
        transform: scale(0%);
      }
    }
  }

  .header-item {
    width: 10rem;
    height: 10rem;
    // border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(50% - 5rem);
    @include headerItemTransition();

    &.logo {
      top: calc(50% - 12rem);

      .main-logo {
        width: 100%;
        height: auto;
      }
    }
    &.title {
      top: calc(50% - 2rem);
      height: 4rem;

      h1 {
        @include headerItemTransition();
      }
    }
    &.mixer {
      top: calc(50% - 0.5rem);
      height: 1rem;
      transform: scale(0%);
      z-index: 12;
    }
    &.picker {
      top: calc(50% + 3rem);
      height: 2rem;
    }

    &.settings {
      width: 4rem;
      height: 4rem;
      top: 0;
      left: calc(100% - 4rem);
      z-index: 12;

      i {
        font-size: 1.5rem;
      }
    }
  }

  &.compact {
    height: 4rem;
    flex-direction: row;
    justify-content: space-between;

    .header-item {
      height: 4rem;
      top: 0;

      &.logo {
        left: 1rem;
        width: 2.8rem;
        height: 4rem;
      }
      &.title {
        left: 4rem;
        width: 6rem;

        h1 {
          font-size: 2rem;
        }
      }
      &.mixer {
        top: calc(2rem - 0.5rem);
        left: 6rem;
        height: 1rem;
        transform: scale(100%);
      }
      &.picker {
        left: calc(50% - 5rem);

        .form-select-options {
          top: calc(100% - 0.66rem);
        }
      }

      &.settings {
      }
    }

    @include screenSize(lgMobile) {
      .header-item {
        &.logo {
          transform: scale(0%);
        }
        &.title {
          transform: scale(0%);
        }
        &.picker {
          width: calc(100vw - 8rem);
          left: calc(50% - ((100vw - 8rem) / 2));
        }
        &.mixer {
          left: 1rem;
          width: 2rem;
        }
      }
    }
  }

  .guide-vertical,
  .guide-horizontal {
    background-color: #666666;
    position: absolute;
  }
  .guide-vertical {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
  }
  .guide-horizontal {
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
  }
}

.main-logo {
  position: absolute;
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }
}
